const brand = {
  key: 'nomads',
  accessKey: process.env.REACT_APP_NOMADS_ACCESS_KEY,
  name: 'Nomads',
  url: 'https://www.nomads-surfing.com/',

  features: {
    withUidCheck: true,
    withReferenceInput: false,
    withIssueRequest: true,
    withTextRecognition: false,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1668153999/Photos/Logo_NOMAD_SURFING_tf8t4v.png',
    homePage: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716299356/Landing_page_NOMADS_tcld8c.jpg',
    homePageCompressed: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716299356/Landing_page_NOMADS_tcld8c.jpg',
    certificateExample: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1701686134/certificate-phone_h54b8b.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1701687086/certificate-phone_1_y67cxs.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/c_thumb,w_200,g_face/v1668153999/Photos/Logo_NOMAD_SURFING_tf8t4v.png',
    uidExample: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716299836/Localisation_QR_NOMADS_swjero.jpg',
  },
};

export default brand;
